import {UseQueryOptions} from '@tanstack/react-query';

import {getProfileStatus} from '../tool/marketplace/util';
import {FarmerProfile, OwnSupplierQuery, useOwnSupplierQuery} from './graphql';
import queryClient from './queryClient';
import useCurrentUser from './useCurrentUser';

export const useOwnSupplier = (options?: UseQueryOptions<OwnSupplierQuery, unknown, OwnSupplierQuery['ownSupplier']>) =>
  useOwnSupplierQuery({}, {select: data => data.ownSupplier, ...options});

export const useIsSupplier = () => {
  const currentUser = useCurrentUser();
  const {data: ownSupplier} = useOwnSupplier();
  return currentUser?.farmerProfile?.includes(FarmerProfile.Supplier) || !!ownSupplier;
};

export const useOwnSupplierStatus = () => {
  const {data: supplier} = useOwnSupplier();
  return getProfileStatus(supplier);
};

export const useCanBecomeSupplier = () => {
  const user = useCurrentUser();
  const isSupplier = useIsSupplier();
  const {data: supplier, isLoading} = useOwnSupplier({enabled: !!user});
  return !isSupplier && !isLoading && !supplier;
};

export const invalidateOwnSupplier = () => {
  void queryClient.invalidateQueries(useOwnSupplierQuery.getKey());
};

export const resetOwnSupplier = () => queryClient.resetQueries(useOwnSupplierQuery.getKey());
