import {getApp, getApps} from '@firebase/app';
import {getAuth} from 'firebase/auth';

import {DEFAULT_ERROR, GraphQLReceivedError, GraphQLSingleError} from '../../controllers/errors';
import {installAuthCookie} from '../firebase/public';

export function fetcher<TData, TVariables>(query: string, variables?: TVariables, options?: RequestInit['headers']) {
  return async (): Promise<TData> => {
    // Refresh auth token if necessary and ensure it's saved to cookies
    if (getApps().length > 0) {
      const firebaseAuth = getAuth(getApp());
      if (firebaseAuth.currentUser) {
        await installAuthCookie(firebaseAuth.currentUser);
      }
    }

    // The following works server-side (where BASE_URL is set), and client-side (where the URL is relative to current host).
    const url = (process.env.BASE_URL ?? '') + '/api/graphql';

    let res: Response;
    const [key, file] = getFileVariable(variables);
    if (key) {
      // If any of the variables is a File, we need to use multipart/form-data.
      const form = new FormData();
      form.append('operations', JSON.stringify({query, variables: {...variables, [key]: null}}));
      form.append('map', JSON.stringify({0: [`variables.${key}`]}));
      form.append('0', file, file.name);

      res = await fetch(url, {
        method: 'POST',
        ...{headers: options},
        body: form,
      });
    } else {
      // Otherwise, we can use application/json
      res = await fetch(url, {
        method: 'POST',
        ...{headers: {'Content-Type': 'application/json', ...options}},
        body: JSON.stringify({query, variables}),
      });
    }

    const json = await res.json();

    if (json.errors) {
      const error = GraphQLReceivedError.parse(json.errors);
      if (!error) throw new Error(DEFAULT_ERROR);

      if (error.errors.every(e => isInputError(e))) {
        // If any of the user inputs is invalid, we ignore the error to make sure other inputs are not lost.
        console.log('Ignoring input error', error);
      } else {
        throw error;
      }
    }

    return json.data;
  };
}

function isInputError(error: GraphQLSingleError) {
  return Array.isArray(error?.path) && error.path[0] === 'scenario' && error.path[1] === 'inputs';
}

function getFileVariable(variables: any): [string, File] | [null, null] {
  if (typeof variables !== 'object') return [null, null];

  for (const [key, value] of Object.entries(variables)) {
    if (value instanceof File) {
      return [key, value];
    }
  }
  return [null, null];
}
