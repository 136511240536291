import {isNullish} from '../../common/util';
import {Point, Tracking} from '../../query/graphql';
import {POWER_CAPACITIES} from '../resources/capacities';
import {CropId, getCrop} from '../resources/crops';
import {PROPERTIES} from '../resources/properties';
import Defaults from './data/defaults';
import {CropMap, Inputs, IsCostActive, SimpleValue} from './types';

// Function overload signature
export function formatMoney(num: number, optionalFraction?: boolean): string;

// Function overload signature
export function formatMoney(num?: null, optionalFraction?: boolean): null;

/**
 * Returns a string with an English representation of the given number.
 *
 * @param num the number to represent
 * @param optionalFraction if false (default), the representation will have exactly 2 fractional digits. If true, the
 * representation will have either no fractional digits (if it's a whole number) or exactly 2.
 */
export function formatMoney(num?: number | null, optionalFraction: boolean = false) {
  if (typeof num !== 'number') return null;
  const rounded = Math.round(num * 100) / 100; // Round to 2 fraction digits
  const minFractionDigits = rounded === Math.trunc(rounded) ? 0 : 2; // If any number of fraction digits is needed, pick 2 digits
  return formatNumber(num, optionalFraction ? minFractionDigits : 2, 2);
}

export function formatNumber(num: number, minimumFractionDigits?: number, maximumFractionDigits?: number): string;

export function formatNumber(
  num: number | undefined | null,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number
): null;

export function formatNumber(
  num: number | undefined | null,
  minimumFractionDigits: number = 0,
  maximumFractionDigits: number = 2
) {
  if (typeof num !== 'number') return null;
  return num.toLocaleString('en-GB', {minimumFractionDigits, maximumFractionDigits});
}

export const formatCoordinate = (coordinate?: number, fractionDigits: number = 5): string => {
  if (coordinate === undefined) return '';
  return coordinate.toFixed(fractionDigits);
};

export const formatCoordinates = ({latitude, longitude}: Point, fractionDigits: number = 5): string => {
  return [latitude, longitude].map(p => formatCoordinate(p, fractionDigits)).join(', ');
};

export const formatLocation = (inputs: Pick<Inputs, 'postcode' | 'coordinates'>): string | null => {
  if (inputs.postcode) return inputs.postcode;
  if (inputs.coordinates) return formatCoordinates(inputs.coordinates);
  return null;
};

export const formatSimpleValue = (value?: SimpleValue | null): string => {
  if (isNullish(value)) return '';
  /**
   * Special cases
   * - LocationOutput is not used to display values, no need to be handled
   * - Point is handled using formatLocation during table field definition
   */
  return value.toString();
};

export function toFixed<T extends number | undefined | null>(num: T, fractionDigits: number = 2): T {
  if (typeof num !== 'number') return undefined as T;
  return parseFloat(num.toFixed(fractionDigits)) as T;
}

export function sum(array: number[]) {
  return array.reduce((acc, val) => acc + val, 0);
}

export function fillWithDefaults<T extends Partial<Inputs>>(inputs: T): T {
  const inputCopy = {...inputs};

  Object.entries(inputCopy).forEach(([k, v]) => {
    const key = k as keyof Inputs;
    if (v === null) {
      inputCopy[key] = Defaults[key] ?? (null as any); // Typecasting is safe, as both objects are "partials" of Inputs
    }
  });

  return inputCopy;
}

export type KeyWithCrop<K extends string, C extends CropId> = `${K}+${C}`;

export function generateKey<K extends string, C extends CropId>(key: K, cropId: C): KeyWithCrop<K, C> {
  return `${key}+${cropId}`;
}

export function parseKey<K extends string, C extends CropId>(combinedKey: KeyWithCrop<K, C>): {key: K; cropId: C} {
  const [key, cropId] = combinedKey.split('+');
  return {key: key as K, cropId: cropId as C};
}

export function getInitials(name: string) {
  const names = name.split(' ');
  return names
    .slice(0, 3)
    .map(n => n.substring(0, 1).toUpperCase())
    .join('');
}

/**
 * Return a new Tracking object with length equal to the given lifetime, where the yield of the year at the given index
 * is set to the given yield, and the rest of the years match the given tracking object (if present) or null.
 */
export function buildNewTracking(
  lifetime: number,
  tracking: Tracking,
  yearIdx: number,
  theYield: number | null
): Tracking {
  const newYears = Array(lifetime)
    .fill(null)
    .map((value, idx) => {
      if (idx === yearIdx) {
        return theYield === null ? null : {yield: theYield};
      }
      return tracking?.years[idx] ?? null;
    });
  return {...tracking, years: newYears};
}

export function buildCropMap<T>(callback: (cropId: CropId) => T): CropMap<T> {
  return Object.fromEntries(Object.values(CropId).map(id => [id, callback(id)])) as {[id in CropId]: T};
}

export const isEstablishmentYear: IsCostActive = ({yearIndex}) => yearIndex === 0;

export const isNotEstablishmentYear: IsCostActive = ({yearIndex}) => yearIndex > 0;

export const isHarvestYear: IsCostActive = ({isHarvest}) => isHarvest;

export const alwaysActive: IsCostActive = () => true;

/**
 * Returns the heat consumption value from the given inputs. If `heatConsumption` input is not set, try to get the
 * value based on legacy `propertyType` input.
 */
export function getHeatConsumption(inputs: Pick<Inputs, 'heatConsumption' | 'propertyType'>) {
  return inputs.heatConsumption ?? (inputs.propertyType ? PROPERTIES[inputs.propertyType].annualConsumption : null);
}

/**
 * Returns the fuel requirement value from the given inputs. If `fuelRequirement` input is not set, try to get the
 * value based on legacy `powerCapacity` input.
 */
export function getFuelRequirement(inputs: Pick<Inputs, 'fuelRequirement' | 'powerCapacity'>) {
  return (
    inputs.fuelRequirement ?? (inputs.powerCapacity ? POWER_CAPACITIES[inputs.powerCapacity].fuelRequirement : null)
  );
}

export function getStaggerStatus(
  cropId: CropId,
  landSize: number | null,
  stagger: number | null,
  fieldSizes: number[] | null
) {
  const canStagger = getCrop(cropId).canStagger;
  const isStaggering = canStagger ? (stagger ?? 3) > 1 : false;
  const fieldCount = fieldSizes?.length || 3;
  const defaultFieldSizes: number[] | undefined =
    canStagger && landSize ? Array(fieldCount).fill(toFixed(landSize / fieldCount, 2)) : undefined;

  return {
    canStagger,
    isStaggering,
    fieldSizes,
    defaultFieldSizes,
  };
}
