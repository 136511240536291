import classNames from 'classnames';
import React, {
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  UIEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {ChevronDownIcon} from '../../../resources/Icons';
import Col from '../../common/Col';

type PropsType = PropsWithChildren<{
  buttonContent: ReactNode;
  variant?: 'primary' | 'secondary' | 'dark' | 'rounded';
  floating?: boolean;
  className?: string; // Applies to whole component
  menuClassName?: string; // Applies to menu
  hideChevron?: boolean;
  disabled?: boolean;
}>;

const Shadow = ({variant, visible}: {variant: 'top' | 'bottom'; visible: boolean}) => (
  <div
    className={classNames(
      'absolute w-full h-4 transition-opacity',
      variant === 'top' ? 'top-0 topScrollShadow' : 'bottom-0 bottomScrollShadow',
      !visible && 'opacity-0'
    )}
  />
);

const Dropdown = (props: PropsType) => {
  const {
    buttonContent,
    variant = 'primary',
    children,
    floating,
    className,
    menuClassName,
    hideChevron,
    disabled,
  } = props;

  const menuRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showBottomShadow, setShowBottomShadow] = useState(false);

  const showHideShadows = useCallback((e: Element) => {
    const atTop = e.scrollTop === 0;
    const atBottom = e.scrollHeight - e.scrollTop - e.clientHeight === 0;
    setShowTopShadow(!atTop);
    setShowBottomShadow(!atBottom);
  }, []);

  const handleScroll = useCallback(
    (evt: UIEvent) => {
      showHideShadows(evt.currentTarget);
    },
    [showHideShadows]
  );

  const blurActiveElement = useCallback(() => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }, []);

  const handleMouseDown = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (menuRef.current?.contains(document.activeElement)) {
        // Blur element on next cycle, after onClick has triggered, to avoid regaining focus
        setTimeout(blurActiveElement, 10);
      }
    },
    [blurActiveElement]
  );

  // Add focusin listener to adjust dropdown position if it exceeds window width
  useEffect(() => {
    const leftPadding = 36;
    const rightPadding = 22;

    menuRef.current?.addEventListener('focusin', () => {
      if (dropdownRef.current) {
        // Reset dropdown position
        dropdownRef.current.style.left = '';
        dropdownRef.current.style.right = '';
        dropdownRef.current.style.width = '';
        // Reduce dropdown width if it exceeds window width minus paddings
        let rect = dropdownRef.current.getBoundingClientRect();
        if (rect.width > window.innerWidth - leftPadding - rightPadding) {
          dropdownRef.current.style.width = window.innerWidth - leftPadding - rightPadding + 'px';
        }
        // Move dropdown to the right if it's outside window limit
        rect = dropdownRef.current.getBoundingClientRect();
        if (rect.left <= 0) {
          dropdownRef.current.style.right = rect.left - 28 + 'px';
        }
      }
    });
  }, []);

  return (
    <div className={classNames('relative group space-y-[.9rem] text-sm', className)} tabIndex={0} ref={menuRef}>
      <button
        className={classNames(
          ' border-thin w-full inline-flex items-center justify-between gap-xs outline-none',
          variant === 'primary' && 'border-gray-100 enabled:hover:border-primary-300',
          variant === 'secondary' && 'border-transparent enabled:hover:border-gray-100',
          variant !== 'dark' && 'enabled:group-focus-within:border-primary-300',
          variant === 'dark' && 'text-white bg-primary-700 undisabled:hover:bg-primary-600 focus:bg-primary-600',
          variant !== 'rounded' && 'p-4 rounded-[10px]',
          variant === 'rounded' &&
            'w-[2rem] h-[2rem] rounded-full border-gray-200 text-gray-200 enabled:group-focus-within:text-primary-300'
        )}
        type="button"
        onMouseDown={disabled ? undefined : handleMouseDown}
        disabled={disabled}
      >
        {buttonContent}
        {!hideChevron && (
          <ChevronDownIcon
            className={classNames(
              'w-[1.4rem] shrink-0 transition-transform duration-300',
              !disabled && 'group-focus-within:-rotate-180',
              variant !== 'dark' && !disabled && 'group-focus-within:text-primary-300'
            )}
          />
        )}
      </button>
      <Col
        ref={dropdownRef}
        className={classNames(
          'border-thin border-gray-100 rounded-[10px] bg-white overflow-hidden invisible',
          !disabled && 'group-focus-within:visible',
          floating && ['absolute z-10', variant == 'rounded' ? 'top-[2.5rem] left-0' : 'top-[4.6rem] right-0'],
          menuClassName
        )}
      >
        <Col
          ref={e => e && showHideShadows(e)}
          onScroll={handleScroll}
          className={classNames(
            'overflow-hidden overflow-y-auto scrollbar-thin',
            'scrollbar-thumb-primary-300 scrollbar-track-gray-100 scrollbar-thumb-rounded-xl scrollbar-track-rounded-xl'
          )}
        >
          <Shadow variant={'top'} visible={showTopShadow} />
          {children}
          <Shadow variant={'bottom'} visible={showBottomShadow} />
        </Col>
      </Col>
    </div>
  );
};

export default Dropdown;
