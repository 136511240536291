import React, {FC, useCallback} from 'react';

import {Option} from '../../decision/types';
import OptionView from './OptionView';

type PropsType = {
  options: Option<string>[];
  value: string[] | undefined;
  onChange: (value: string[]) => void | Promise<void>;
};

const QuestionMultiSelect: FC<PropsType> = props => {
  const {options, value, onChange} = props;

  const handleChange = useCallback(
    async (checked: boolean, val: string) => {
      if (checked) {
        await onChange([...(value || []), val]);
      } else {
        await onChange(value?.filter(v => v !== val) || []);
      }
    },
    [onChange, value]
  );

  const handleClearAll = useCallback(async () => {
    await onChange([]);
  }, [onChange]);

  return (
    <div className={'grid grid-cols-2 gap-xs'}>
      <div className="w-fit text-gray-200 font-normal text-xs">Select all that apply</div>
      <button
        className="w-fit place-self-end text-primary-500 hover:text-shadow font-medium text-xs"
        type="button"
        onClick={handleClearAll}
      >
        Clear all
      </button>
      {options?.map((opt, idx) => (
        <OptionView key={idx} option={opt} checked={value?.includes(opt.value)} onChange={handleChange} />
      ))}
    </div>
  );
};

export default QuestionMultiSelect;
