import lottie from 'lottie-web/build/player/lottie_light';
import {ComponentProps, ForwardedRef, forwardRef, useEffect, useId, useRef} from 'react';
import {mergeRefs} from 'react-merge-refs';

type Props = Omit<ComponentProps<'div'>, 'ref'> & {
  animation: object;
  loop?: boolean;
  autoplay?: boolean;
  // If true, the animation will be scaled to fit inside the container
  contain?: boolean;
  // Number between 0 and 1. The animation will start at this fraction of its duration
  startPointFraction?: number;
};

const LottieAnimation = (
  {animation, loop = false, autoplay = true, contain = false, startPointFraction, ...props}: Props,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const id = useId();
  const animationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      name: id,
      container: animationRef.current as HTMLDivElement,
      renderer: 'svg',
      loop,
      autoplay,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: contain ? 'xMidYMax meet' : 'xMidYMid slice',
      },
    });

    if (startPointFraction && animation) anim.goToAndPlay((animation as any).op * startPointFraction, true);
    return () => lottie.destroy(id);
  }, [animationRef, animation, autoplay, contain, id, loop, startPointFraction]);
  return <div ref={mergeRefs([animationRef, ref])} {...props} />;
};

export default forwardRef(LottieAnimation);
