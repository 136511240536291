import classNames from 'classnames';
import React, {ReactElement, useEffect, useMemo} from 'react';
import ReactModal from 'react-modal';

import Col from '../../common/Col';
import {useBreakpoint} from '../../common/util/useBreakpoint';

// Warning: Don't remove the following line. It's required to prevent Tailwind from pruning this class
// ReactModal__Body--open

export type ModalProps = {
  isOpen: boolean;
  closeable?: boolean; // Defaults to true
  onRequestClose?: () => void;
  className?: string;
  children: React.ReactNode;
  title?: string;
  contentStyle?: React.CSSProperties;
};

const Modal = ({
  isOpen,
  closeable = true,
  onRequestClose,
  className,
  children,
  title,
  contentStyle,
}: ModalProps): ReactElement => {
  const {isMobile} = useBreakpoint('mobile');
  const modalStyles: ReactModal.Styles = useMemo(
    () => ({
      overlay: {position: 'fixed', inset: 0, background: 'rgba(24,24,34,0.5)', zIndex: 9998, overflow: 'auto'},
      content: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: undefined,
        minHeight: '100%',
        border: 0,
        padding: isMobile ? 10 : 40,
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'stretch',
        background: undefined,
        zIndex: 9999,
        ...contentStyle,
      },
    }),
    [contentStyle, isMobile]
  );

  useEffect(() => {
    return () => {
      // Ensure the class is removed when the modal is unmounted. Prevents: https://github.com/reactjs/react-modal/issues/888
      document.body.classList.remove('ReactModal__Body--open');
    };
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel={title && title.replace(' ', '_')}
      style={modalStyles}
      ariaHideApp={false}
      parentSelector={() => {
        const parent = document.getElementById('__next');
        if (!parent) {
          console.warn('Unable to find element with id `__next`. Using document.body as modal parent');
          return document.body;
        }
        return parent;
      }}
      shouldCloseOnEsc={closeable}
      shouldCloseOnOverlayClick={closeable}
      onRequestClose={onRequestClose}
    >
      <Col
        className={classNames(
          'w-full max-w-limit m-auto grow bg-white rounded-xl overflow-hidden shadow-2xl',
          className
        )}
      >
        {children}
      </Col>
    </ReactModal>
  );
};

Modal.displayName = 'Modal';

export default Modal;
