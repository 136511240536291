import {Rating, Supplier} from '../../query/graphql';
import {
  ApiMarketplaceProductMarketplaceProduct,
  ApiMarketplaceSupplierMarketplaceSupplier,
} from '../../strapi/generated/contentTypes';
import {ProcessedValues} from '../../strapi/types';

export type ProfileStatus = 'New' | 'Draft' | 'In review' | 'Published';

export type Review = {
  name?: string;
  rating?: number;
  text?: string;
  date?: string;
};

export type StrapiSupplier = ProcessedValues<ApiMarketplaceSupplierMarketplaceSupplier>;
export type StrapiProduct = ProcessedValues<ApiMarketplaceProductMarketplaceProduct>;

export type SupplierWithRating = Supplier & {
  rating?: Rating;
  reviews?: Review[];
  attributions?: string[];
  url?: string;
};

export enum SortOrder {
  rating = 'Rating: High to Low',
  '-rating' = 'Rating: Low to High',
  name = 'Name: A to Z',
  '-name' = 'Name: Z to A',
}

export const PRODUCT_DEFAULT_SORT: keyof typeof SortOrder = 'name';
export const SUPPLIER_DEFAULT_SORT: keyof typeof SortOrder = 'name';
