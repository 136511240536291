export const DEFAULT_ERROR = 'internal-error';
export const DEFAULT_AUTH_ERROR = 'auth/internal-error';
export const SCENARIO_NOT_FOUND = 'scenario-not-found';
export const SCENARIO_LIMIT_REACHED = 'scenario-limit-reached';
export const SCENARIO_NAME_CONFLICT = 'scenario-name-conflict';
export const SCENARIO_NAME_TOO_LONG = 'scenario-name-too-long';
export const CANNOT_DELETE_LAST_SCENARIO = 'cannot-delete-last-scenario';
export const EMAIL_NOT_VERIFIED = 'email-not-verified';
export const MISSING_AUTHENTICATION = 'missing-authentication';
export const INSUFFICIENT_PERMISSION = 'insufficient-permission';
export const PRIVACY_CONSENT_REQUIRED = 'privacy-consent-required';
export const DB_CONNECTION_ERROR = 'db-connection-error';
export const INVALID_FILE_TYPE = 'invalid-file-type';
export const MAX_UPLOAD_SIZE_EXCEEDED = 'max-upload-size-exceeded';
export const INPUTS_LOCKED = 'inputs-locked';

export class GraphQLSingleError extends Error {
  constructor(
    public readonly message: string,
    public readonly locations: {line: number; column: number}[],
    public readonly path: string[],
    public readonly extensions: Record<any, any>
  ) {
    super(message);
  }
}

export class GraphQLReceivedError extends Error {
  constructor(public readonly message: string, public readonly errors: GraphQLSingleError[]) {
    super(message);
  }

  static parse(errors: any) {
    if (Array.isArray(errors) && errors.length) {
      return new GraphQLReceivedError(
        errors[0].message,
        errors.map((error: any) => new GraphQLSingleError(error.message, error.locations, error.path, error.extensions))
      );
    }
  }
}

type FieldError = {message: string; path: string[]};

type ValidationErrors = {
  globalError: string | undefined;
  fieldErrors: FieldError[];
};

export function processStrapiValidationErrors(e: GraphQLSingleError): ValidationErrors {
  const result: ValidationErrors = {globalError: undefined, fieldErrors: []};

  if (e.extensions?.strapi?.name == 'ValidationError' && Array.isArray(e?.extensions?.strapi?.details.errors)) {
    e.extensions.strapi.details.errors.forEach((e: any) => {
      if (Array.isArray(e.path) && e.path.length) {
        result.fieldErrors.push({
          message: e.message,
          path: e.path,
        });
      } else if (!result.globalError) {
        result.globalError = e.message;
      }
    });
  } else {
    result.globalError = e.message;
  }

  return result;
}
