import classNames from 'classnames';
import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';

import {RevertIcon} from '../../../../resources/Icons';
import Row from '../../../common/Row';
import {LocationOutput, SimpleValue} from '../../decision/types';
import {requestGeolocatePostcode, UK_POSTCODE_REGEX} from '../../util/requestGeolocatePostcode';
import Map, {BRISTOL} from '../Map';
import AdditionalInfo from './AdditionalInfo';

const INVALID_COORDINATE = -9999;

const sanitiseCoordinate = (value?: number) => {
  if (value === undefined || value === INVALID_COORDINATE) return '';
  return value;
};

export type PropsType<T extends SimpleValue, U extends string> = {
  googleMapsApiKey: string;
  value: LocationOutput | undefined;
  onChange: (value: LocationOutput | null) => void;
  error?: string;
  disabled?: boolean;
};

const QuestionLocation = <T extends SimpleValue, U extends string>(props: PropsType<T, U>) => {
  const {disabled, googleMapsApiKey, value, onChange, error, ...otherProps} = props;
  const [inputMode, setInputMode] = useState<'postcode' | 'coordinate'>('postcode');
  const [inputError, setInputError] = useState<string>();
  const [markerPosition, setMarkerPosition] = useState<{lat: number; lng: number} | undefined>(
    value?.coordinates ? {lat: value.coordinates.latitude, lng: value.coordinates.longitude} : undefined
  );

  const showResetButton = !!value && (value.postcode !== null || value.coordinates !== null);

  useEffect(() => {
    if (inputMode === 'postcode' && !value?.postcode && value?.coordinates) {
      setInputMode('coordinate');
    }
  }, [inputMode, value]);

  const onMarkerPositionUpdate = (lat: number, lng: number) => {
    setInputError(undefined);
    if (inputMode === 'postcode') {
      setInputMode('coordinate');
    }
    setMarkerPosition({lat, lng});
    onChange({postcode: null, coordinates: {latitude: lat, longitude: lng}});
  };

  const handlePostcodeInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputError(undefined);
      const currentValue = event.currentTarget.value.toUpperCase();
      if (!event.currentTarget.checkValidity()) {
        onChange({
          postcode: currentValue,
          coordinates: null,
        });
        setMarkerPosition(undefined);
      } else {
        requestGeolocatePostcode(currentValue)
          .then(({lat, lng}) => {
            setMarkerPosition({lat: Number(lat), lng: Number(lng)});
            onChange({
              postcode: currentValue,
              coordinates: {latitude: Number(lat), longitude: Number(lng)},
            });
          })
          .catch(error => {
            setInputError(error.message);
            onChange({postcode: currentValue, coordinates: null});
          });
      }
    },
    [onChange]
  );

  const handleCoordinateInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, field: 'latitude' | 'longitude') => {
      setInputError(undefined);
      const currentValue = event.currentTarget.value;
      const currentCoordinate = currentValue ? Number(currentValue) : INVALID_COORDINATE;
      const coordinates =
        field === 'latitude'
          ? {latitude: currentCoordinate, longitude: value?.coordinates?.longitude ?? INVALID_COORDINATE}
          : {latitude: value?.coordinates?.latitude ?? INVALID_COORDINATE, longitude: currentCoordinate};

      onChange({postcode: null, coordinates});
      setMarkerPosition({lat: coordinates.latitude, lng: coordinates.longitude});
    },
    [onChange, value?.coordinates?.latitude, value?.coordinates?.longitude]
  );

  const handleInputModeChange = useCallback(() => {
    setInputError(undefined);
    setInputMode(inputMode === 'postcode' ? 'coordinate' : 'postcode');
    onChange(null);
    setMarkerPosition(undefined);
  }, [inputMode, onChange]);

  const handleReset = useCallback(() => {
    setMarkerPosition(undefined);
    onChange(null);
  }, [onChange]);

  const shownLatitudeValue = useMemo(
    () => sanitiseCoordinate(value?.coordinates?.latitude),
    [value?.coordinates?.latitude]
  );

  const shownLongitudeValue = useMemo(
    () => sanitiseCoordinate(value?.coordinates?.longitude),
    [value?.coordinates?.longitude]
  );

  return (
    <fieldset className={'flex flex-col items-stretch'}>
      {inputMode === 'postcode' ? (
        <input
          type={'text'}
          value={value?.postcode ?? ''}
          onChange={handlePostcodeInputChange}
          pattern={UK_POSTCODE_REGEX}
          disabled={disabled}
          placeholder={`example: ${BRISTOL.postcode}`}
          className={classNames('flex-1 mb-xs placeholder:italic placeholder:text-gray-150', {
            error: error || !!inputError,
          })}
          {...otherProps}
        />
      ) : (
        <Row className="gap-x-6">
          <input
            type={'number'}
            value={shownLatitudeValue}
            onChange={e => handleCoordinateInputChange(e, 'latitude')}
            step=".00001"
            disabled={disabled}
            placeholder={`Latitude. Example: ${BRISTOL.lat}`}
            className={classNames('flex-1 mb-xs placeholder:italic placeholder:text-gray-150', {
              error: error || !!inputError,
            })}
            {...otherProps}
          />
          <input
            type={'number'}
            value={shownLongitudeValue}
            onChange={e => handleCoordinateInputChange(e, 'longitude')}
            step=".00001"
            disabled={disabled}
            placeholder={`Longitude. Example: ${BRISTOL.lng}`}
            className={classNames('flex-1 mb-xs placeholder:italic placeholder:text-gray-150', {
              error: error || !!inputError,
            })}
          />
        </Row>
      )}
      <Row className="justify-between mb-xs">
        <div className="w-fit text-gray-200 font-normal text-xs">
          {inputMode === 'postcode' ? 'Enter postcode' : 'Enter coordinates'}
        </div>
        <button
          className="w-fit place-self-end text-primary-500 hover:text-shadow font-medium text-xs"
          type="button"
          onClick={handleInputModeChange}
        >
          {inputMode === 'postcode' ? 'Enter coordinates instead' : 'Enter postcode instead'}
        </button>
      </Row>
      <Map
        googleMapsApiKey={googleMapsApiKey}
        markerPosition={markerPosition}
        onMarkerPositionUpdate={onMarkerPositionUpdate}
        onError={setInputError}
      />
      <AdditionalInfo
        error={error || inputError}
        info={<>{markerPosition ? 'Drag the picker to adjust location' : 'Click on the map to pick a location'}</>}
      />
      <AdditionalInfo
        className={classNames({invisible: !showResetButton})}
        info={
          <button type={'button'} className={'!mt-md hover:text-primary-800'} onClick={handleReset}>
            <Row className={'items-start justify-center gap-[8px]'}>
              <RevertIcon className={'shrink-0 w-[14px] h-[14px] mt-[4px]'} />
              <span>Clear your location to revert back to using generic yields</span>
            </Row>
          </button>
        }
      />
    </fieldset>
  );
};

export default QuestionLocation;
