import {ArrowLeftIcon, ArrowRightIcon} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Link from 'next/link';
import React, {ComponentProps, ReactElement, ReactNode, useMemo} from 'react';

import {Spinner} from '../../../../resources/Icons';
import Row from '../../../common/Row';

type CommonProps = {
  variant: 'primary' | 'secondary' | 'prominent' | 'navigation' | 'link';
  accessory?: 'back' | 'forward' | ReactNode;
  align?: 'left' | 'right' | 'center';
  progress?: number; // [0..1]
  loading?: boolean;
};

type LinkProps = {href: string; disabled?: boolean; external?: boolean} & CommonProps & ComponentProps<typeof Link>;

type ButtonProps = {href?: never} & CommonProps & ComponentProps<'button'>;

type Props = LinkProps | ButtonProps;

const Button = ({className, variant, accessory, align, progress, children, disabled, loading, ...props}: Props) => {
  const progressPercentage = useMemo(() => {
    if (typeof progress !== 'number') return null;
    if (progress <= 0) return 0;
    if (progress >= 1) return 100;
    return progress * 100;
  }, [progress]);
  const isFullProgress = progressPercentage === 100;

  const Accessory = useMemo<ReactNode>(() => {
    if (!accessory) return null;
    const className = classNames(
      'flex-none h-9 w-9',
      variant === 'secondary' && 'group-undisabled:group-hover:text-primary-300'
    );
    if (accessory === 'back') return <ArrowLeftIcon className={className} />;
    if (accessory === 'forward') return <ArrowRightIcon className={className} />;
    const accessoryElement = accessory as ReactElement;
    return React.cloneElement(accessoryElement, {
      className: accessoryElement.props['className'],
    });
  }, [accessory, variant]);

  const isDisabled = disabled || loading;
  const external = 'external' in props && props.external;
  if (external) delete props.external; // Avoid passing invalid `external` prop to the `Link` component

  const Content = useMemo(
    () => (
      <>
        <div
          className={classNames('absolute inset-0 h-[0.5rem] bg-gray-100', {
            invisible: progressPercentage === null,
          })}
        />
        <div
          className={classNames('absolute inset-0 h-[0.5rem] bg-primary-300', {invisible: progressPercentage === null})}
          style={progressPercentage !== null ? {width: `${progressPercentage}%`} : {}}
        />
        <Row
          className={classNames(
            'items-center space-x-3',
            variant === 'navigation' ? 'py-8' : variant === 'link' ? null : 'py-4 px-8',
            {
              invisible: loading,
            }
          )}
        >
          {accessory && accessory !== 'forward' && Accessory}
          <div
            className={classNames('grow', {
              'text-center': (!align && !accessory) || align === 'center',
              'text-right': align === 'right',
              'text-left': align === 'left' || (!align && accessory),
              'external-link': external,
            })}
          >
            {children}
          </div>
          {accessory && accessory === 'forward' && Accessory}
        </Row>
        {loading && (
          <div className="flex absolute inset-0 items-center justify-center">
            <Spinner className={'text-white'} />
          </div>
        )}
      </>
    ),
    [Accessory, accessory, align, children, external, loading, progressPercentage, variant]
  );

  const componentClassName = classNames(
    'inline-block relative disabled:bg-gray-100 disabled:border-gray-100 disabled:text-white overflow-hidden',
    {
      'text-sm text-white bg-primary-700 undisabled:hover:bg-primary-600 rounded-[1rem] border-thin border-primary-700 undisabled:hover:border-primary-600 hover:no-text-shadow':
        variant === 'primary',
      'text-sm text-primary-700 group bg-white rounded-[1rem] border-thin hover:no-text-shadow':
        variant === 'secondary',
      'text-sm border-primary-300 undisabled:hover:text-white undisabled:hover:bg-primary-700':
        variant === 'secondary' && isFullProgress,
      'text-sm border-primary-700 undisabled:hover:border-primary-300': variant === 'secondary' && !isFullProgress,
      'text-sm text-white bg-primary-600 rounded-[1rem] hover:no-text-shadow': variant === 'prominent',
      'text-sm pseudoSemibold text-primary-700 font-normal undisabled:hover:font-semibold hover:no-text-shadow bg-white':
        variant === 'navigation',
      'text-sm text-primary-500 hover:text-shadow': variant === 'link',
    },
    className
  );

  if ('href' in props && props.href !== undefined) {
    return !isDisabled ? (
      <Link className={componentClassName} {...props}>
        {Content}
      </Link>
    ) : (
      <button className={componentClassName} disabled={true}>
        {Content}
      </button>
    );
  }

  return (
    <button className={componentClassName} disabled={isDisabled} {...props}>
      {Content}
    </button>
  );
};

export default Button;
